<template>
    <validation-observer tag="div" ref="observer">
        <b-overlay :show="loading" no-wrap></b-overlay>
        <div>
            <b-card class="mt-3" :header="$t('common.group.tax')">
                <b-card-text>
                    <b-row>
                        <b-col sm="3">
                            <zw-input-group v-model="form.currency"
                                            icon="coins"
                                            name="currency"
                                            :label-prefix="labelPrefix"
                            ></zw-input-group>
                        </b-col>
                        <b-col sm="3">
                            <zw-input-group v-model="form.vatId"
                                            icon="coins"
                                            name="vatId"
                                            :label-prefix="labelPrefix"
                            ></zw-input-group>
                        </b-col>
                        <b-col sm="3">
                            <zw-input-group v-model="form.vatNumber"
                                            icon="coins"
                                            name="vatNumber"
                                            :label-prefix="labelPrefix"
                            ></zw-input-group>
                        </b-col>
                        <b-col sm="3">
                            <zw-input-group v-model="form.vat"
                                            icon="coins"
                                            name="vat"
                                            :label-prefix="labelPrefix"
                            ></zw-input-group>
                        </b-col>
                    </b-row>
                </b-card-text>
            </b-card>

            <b-row>
                <b-col sm="6"></b-col>
                <b-col sm="3">
                    <b-button block @click="onSubmit(false,false)" variant="primary">
                        {{ $t('common.button.save') }}
                    </b-button>
                </b-col>
                <b-col sm="3">
                    <b-button block @click="onSubmit(false,true)" variant="info">
                        {{ $t('common.button.save_and_stay') }}
                    </b-button>
                </b-col>
            </b-row>
        </div>
    </validation-observer>
</template>

<script>
import {mapGetters} from "vuex";
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import ValidateMixin from "@/mixins/validate";

export default {
    name: 'customer-tax-form',
    mixins: [ValidateMixin, commonSave],
    props: {
        customerId: {
            type: Number,
        },
        sidebar: {}
    },
    data() {
        return {
            loading: true,
            labelPrefix: 'customer.label.',
            form: {},
            defaultForm: {
                ignore_server_validation: true,
            },
        }
    },
    mounted() {
        this.shown()
    },
    methods: {
        ...mapGetters('Customer', ['getCustomer']),
        shown() {
            this.$store.dispatch('Customer/fetchCustomer', this.customerId)
                .then(() => {
                    this.form = JSON.parse(JSON.stringify({...this.defaultForm, ...this.getCustomer()}))
                    this.$refs['observer'].reset()
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        onSubmit(openOffering = false, stay = false) {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true
                    let formData = JSON.parse(JSON.stringify(this.form))

                    this.$store.dispatch('Customer/saveCustomer', formData)
                        .then((response) => {
                            if (!stay) {
                                this.commonAfterSave(response, this.sidebar)
                            } else {
                                this.$root.$children[0].updateFormValues('customer-modal')
                                this.shown()
                            }
                        })
                        .catch(errors => {
                            if (errors) {
                                this.loading = false
                                this.$refs['observer'].setErrors(errors)
                            }
                            this.showValidationError()
                        })
                        .finally(() => {
                            this.loading = false
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
    }
}
</script>